import "./App.css"
import { Uploader } from "./utils/upload"
import { useEffect, useState } from "react"
import { TextField, Button, CircularProgress, IconButton } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Item from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useSearchParams } from "react-router-dom";

import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from 'notistack';

function App() {
	const [file, setFile] = useState(undefined)
	const [pgvalue, setPgvalue] = useState(0)
	const [uploading, setUploading] = useState(false)
	const [perf, setPerf] = useState(undefined)
	const [baseUrl, setBaseUrl] = useState(undefined)
	const [partsize, setPartsize] = useState(500)
	const [numuploads, setNumuploads] = useState(2)
	const [ta, setTa] = useState(undefined)
	const [searchParams, setSearchParams] = useSearchParams();

	const [qryEmail, setQryEmail] = useState(searchParams.get("email"));
	const [hideEmail, setHideEmail] = useState(!!qryEmail);
	const [email, setEmail] = useState(qryEmail)
	const [qryDev, setQryDev] = useState(searchParams.get("dev"));


	useEffect(() => {
		console.log(qryEmail);
		searchParams.forEach((val,key) => console.log(key, ":", val));
		if (file) {
			let filename = file.name;
			const emailstr = email ?? "anonymous";
			const folder = emailstr.replace(/[^a-z0-9]/gi, '_').toLowerCase();

			const uploaderOptions = {
				file: file,
				baseURL: 'https://dba3dhx1ii.execute-api.ap-southeast-2.amazonaws.com/prod/',
				chunkSize: partsize,
				threadsQuantity: numuploads,
				useTransferAcceleration: ta,
				folder: folder
			}

			let percentage = undefined
			setPgvalue(0)
			setUploading(false)
			setPerf("-")
			const uploader = new Uploader(uploaderOptions)
			const tBegin = performance.now()
			uploader
				.onProgress(({ percentage: newPercentage }) => {
					// to avoid the same percentage to be logged twice
					if (percentage === 100) {
						setPerf((performance.now() - tBegin) / 1000)
					}
					if (newPercentage !== percentage) {
						percentage = newPercentage
						setPgvalue(percentage)
						setUploading(true)
					}
				})
				.onError((error) => {
					setFile(undefined)
					setUploading(false)
					console.error(error)
					enqueueSnackbar(error, {variant:"error"})
				})
				.onComplete(() => {
					enqueueSnackbar(`File '${filename}' uploaded successfully`, {variant:"success"})
					setFile(undefined)
					setUploading(false)
				})

			uploader.start()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [file])

	function LinearProgressWithLabel(props) {
		return (
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Box sx={{ width: '100%', mr: 1 }}>
					<LinearProgress variant="determinate" {...props} />
				</Box>
				<Box sx={{ minWidth: 35 }}>
					<Typography variant="body2" color="text.secondary">{`${Math.round(
						props.value,
					)}%`}</Typography>
				</Box>
			</Box>
		);
	}

	return (
		<div >
			<SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'right' }} persist={true} 
			action={(snackbarId) => (
				<IconButton variant="text" size="small" onClick={() => closeSnackbar(snackbarId)}>
					<CloseIcon fontSize="inherit" />
				</IconButton>
			)}
			/>
			{
				!!qryDev ? qryEmail : null
			}
			<span style={{color:"#FFFFFF"}}>v1</span>
			<Box sx={{ flexGrow: 1 , width:800, margin: 'auto', marginTop:'2em',}}>
				<Grid container spacing={2}>
					{!hideEmail ? <Grid xs={12}>
						<Item>
							<TextField id="outlined-basic" label="Enter Your E-mail" variant="outlined"
								onChange={(e) => {
									setEmail(e.target?.value)
								}} required />
						</Item>
					</Grid> : null
					}
					<Grid xs={4}>
						<Item>
							<Button
							component="label"
							variant="outlined"
							disabled={!(hideEmail || !!email) || uploading}
							startIcon={uploading ? <CircularProgress size={20}/> : <UploadFileIcon />}
							sx={{ marginRight: "1rem" }}
						>
							Select File to Upload
							<input type="file" hidden onChange={(e) => {
								setFile(e.target?.files?.[0])
							}} />
						</Button>
						
						</Item>
					</Grid>
					<Grid xs={8}>
						<Item><LinearProgressWithLabel value={pgvalue} /></Item>
					</Grid>
				</Grid>
			</Box>
			{/* <div style={{ backgroundColor: "#e2e2e2", padding: "20px", margin: "10px"}}>    
        <strong style={{display: "block"}}>Step 1 - Enter API URL</strong><br/>
        <input type="text" id="urlinput" style={{width: "50%"}} placeholder="https://example.execute-api.example.amazonaws.com/example/" 
               onChange={(e) => {
                setBaseUrl(e.target?.value)
               }}
        />
      </div>   */}

			{/* <div style={{ backgroundColor: "#e2e2e2", padding: "20px", margin: "10px" }}>
				<strong style={{ display: "block" }}>Enter e-mail</strong><br />
				<input type="text" id="urlinput" style={{ width: "50%" }} placeholder=""
					onChange={(e) => {
						setEmail(e.target?.value)
					}}
				/>
			</div>
			<div style={{ backgroundColor: "#e2e2e2", padding: "20px", margin: "10px", display: "none" }}>
				<strong style={{ display: "block" }}>Step 2 - Choose part size (MB)</strong><br />
				<input type="number" id="pu" min="5" max="500" value="500"
					onChange={(e) => {
						setPartsize(e.target?.value)
					}}
				/>
			</div>
			<div style={{ backgroundColor: "#e2e2e2", padding: "20px", margin: "10px", display: "none" }}>
				<strong style={{ display: "block" }}>Step 3 - Choose number of parallel uploads</strong><br />
				<input type="number" id="pu" min="5" max="10" value="1"
					onChange={(e) => {
						setNumuploads(e.target?.value)
					}}
				/>
			</div>
			<div style={{ backgroundColor: "#e2e2e2", padding: "20px", margin: "10px", display: "none" }}>
				<strong style={{ display: "block" }}>Step 4 - Use Transfer Acceleration</strong><br />
				<input type="checkbox" id="ta"
					onChange={(e) => {
						setTa(e.target?.checked)
					}}
				/>
			</div>
			<div style={{ backgroundColor: "#e2e2e2", padding: "20px", margin: "10px" }}>
				<strong style={{ display: "block" }}>Step 5 - Choose a file</strong><br />
				<input type="file" id="fileinput"
					onChange={(e) => {
						setFile(e.target?.files?.[0])
					}}
				/>



			</div>
			<div style={{ backgroundColor: "#e2e2e2", padding: "20px", margin: "10px" }}>
				<strong style={{ display: "block" }}>Step 6 - Monitor</strong><br />
				<span id="output">{pgvalue}% ({perf} sec)</span>
			</div> */}

		</div>
	)
}

export default App
